@import "../../styles/index.scss";

.list {
  max-width: max-content;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $spacing-04;
}

.item {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: $breakpoint-l) {
  .list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
