@import "../../styles/index.scss";

.home {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  transition: $loading;
}

.profileContainer {
  width: min-content;
  margin: 0;
  padding: $spacing-08 $spacing-04;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-02;
  background: url("../../assets/bg.png") no-repeat center center;
  background-size: cover;
}

.portfolioContainer {
  margin: 0;
  padding: $spacing-08;
  box-sizing: border-box;
  overflow: auto;
}

.fade {
  opacity: 0;
}

@media screen and (max-width: $breakpoint-l) {
  .home {
    height: auto;
    flex-direction: column;
  }

  .profileContainer {
    width: auto;
    padding: $spacing-08 $spacing-02 $spacing-04 $spacing-02;
  }

  .portfolioContainer {
    padding: $spacing-04;
    display: flex;
    justify-content: center;
    overflow: visible;
  }
}

@media screen and (max-height: $screen-height-s) {
  .profileContainer {
    padding: $spacing-08 $spacing-02 $spacing-04 $spacing-02;
  }
}
