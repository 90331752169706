$black: #000000;
$light-grey: #a7a7a7;
$white: #ffffff;

$font-family: "Source Sans Pro", sans-serif;

$font-size-1: 0.75rem;
$font-size-2: 0.875rem;
$font-size-3: 1rem;
$font-size-4: 1.25rem;
$font-size-5: 2rem;

$font-weight-1: 400;
$font-weight-2: 600;
$font-weight-3: 700;

$spacing-01: 0.5rem;
$spacing-02: 1rem;
$spacing-03: 1.5rem;
$spacing-04: 2rem;
$spacing-05: 2.5rem;
$spacing-06: 3rem;
$spacing-07: 3.5rem;
$spacing-08: 4rem;
$spacing-09: 4.5rem;
$spacing-10: 5rem;
$spacing-11: 5.5rem;
$spacing-12: 6rem;
$spacing-13: 6.5rem;
$spacing-14: 7rem;
$spacing-15: 7.5rem;
$spacing-16: 8rem;
$spacing-17: 8.5rem;
$spacing-18: 9rem;
$spacing-19: 9.5rem;
$spacing-20: 10rem;

$breakpoint-s: 576px;
$breakpoint-m: 768px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1440px;

$screen-height-s: 900px;

$border-black: 1px solid $black;
$border-light-grey: 1px solid $light-grey;
$border-white: 1px solid $white;

$border-radius: 5px;

$button-padding: calc($spacing-01 / 4) $spacing-02;

$transition: all 0.3s ease-out;

$loading: opacity 1s ease-out;
