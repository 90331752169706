@import "../../styles/index.scss";

.footer {
  margin: 0;
  padding: 0;
}

.text {
  font-size: $font-size-2;
  color: $white;
}

.button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-size: $font-size-2;
  font-family: $font-family;
  color: $white;
  cursor: pointer;
}
