@import "../../styles/index.scss";

.profile {
  max-width: min-content;
  margin: 0 auto;
  padding: $spacing-06;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-02;
  background-color: $white;
  border-radius: $border-radius;
  overflow: hidden;
}

.photo {
  height: $spacing-20;
  width: $spacing-20;
  border-radius: 50%;
}

.h1 {
  white-space: nowrap;
}

.headline {
  font-size: $font-size-3;
  font-weight: $font-weight-2;
  white-space: nowrap;
}

.location {
  font-size: $font-size-2;
  font-weight: $font-weight-1;
  white-space: nowrap;
}

.hr {
  width: 60%;
  margin: $spacing-01 0;
  padding: 0;
}

.summary {
  font-size: $font-size-2;
  font-weight: $font-weight-2;
}

.list {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
}

.item {
  margin: 0;
  padding: 0;
}

.a {
  margin: 0;
  padding: $spacing-01;
  color: $black;
  display: flex;

  &:hover {
    color: $white;
    background-color: $black;
  }
}

.icon {
  margin: 0;
  padding: 0;
  font-size: $font-size-4;
}

.enter {
  margin: $spacing-02 0 0 0;
  padding: $button-padding;
  border: $border-black;
  box-sizing: border-box;
  color: $black;
  font-size: $font-size-3;
  font-weight: $font-weight-1;
  transition: $transition;
  text-decoration: none;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .profile {
    max-width: none;
    padding: $spacing-06 $spacing-03;
  }
}

@media screen and (max-height: $screen-height-s) {
  .profile {
    padding: $spacing-02;
    gap: 0;
  }
}
