@import "../../styles/index.scss";

.portfolio {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: $spacing-04;
}

.articleListContainer {
  margin: 0;
  padding: 0;
}
