@import "../../styles/index.scss";

.article {
  height: 100%;
  width: 100%;
  max-width: 30rem;
  margin: 0;
  padding: 0;
  border-radius: $border-radius;
  border: $border-light-grey;
}

.image {
  height: 15rem;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: $border-radius $border-radius 0 0;
}

.overlay {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: $spacing-04;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #00000080;
  opacity: 0;
  transition: $transition;

  &Shown {
    opacity: 1;
  }
}

.summary {
  color: $white;
  font-size: $font-size-3;
  font-weight: $font-weight-1;
}

.read {
  margin: 0;
  padding: $button-padding;
  border: $border-white;
  color: $white;
  font-size: $font-size-2;
  font-weight: $font-weight-1;
  text-decoration: none;

  &:hover {
    background-color: $white;
    color: $black;
  }
}

.textContainer {
  margin: 0;
  padding: $spacing-02;
  display: flex;
  flex-direction: column;
  gap: $spacing-01;
}

.sourceContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.source {
  margin: 0;
  padding: 0;
  font-size: $font-size-2;
  font-weight: $font-weight-3;
  text-transform: uppercase;
}

.date {
  margin: 0;
  padding: 0;
  font-size: $font-size-2;
  font-weight: $font-weight-2;
}

.hr {
  width: 100%;
  margin: 0;
  padding: 0;
}

.title {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: $font-size-4;
}

.a {
  color: $black;
  text-decoration: none;

  &:hover {
    background-color: $black;
    color: $white;
  }
}
