@import "./variable.scss";

html,
body,
#root,
.app,
main {
  height: 100%;
  width: 100%;
  font-family: $font-family;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
