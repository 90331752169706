@import "../../styles/index.scss";

.landing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../assets/bg.png") no-repeat center center fixed;
  background-size: cover;
  transition: $loading;
}

.profileContainer {
  margin: 0;
  padding: $spacing-08 0;
}

.fade {
  opacity: 0;
}
