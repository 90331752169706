@import "./variable.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  color: $black;
  cursor: default;
}

h1 {
  font-size: $font-size-5;
}

h2 {
  font-size: $font-size-4;
}
